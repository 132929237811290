/***************************
**********GLOBAL************
****************************/
$orange: rgba(255, 234, 0, 0.65);
$yellow: #FFFF1A;
$black: #08051f;
$white: #ffffff;
$hover-transition: 0.3s;
//$main-color: #F7F3FF;
$main-color: #FFFFFF; // à supprimer background
$dark-blue: #25252B;
$section-color: #403E6B;
$resume-bg: linear-gradient(143deg, rgba(64, 62, 107, 1) 44%, rgba(37, 37, 43, 1) 100%);

a {
  color: $black;
  transition: $hover-transition;
}


body {
  // font-family: 'Yomogi', cursive;
  font-family: 'Varela Round', sans-serif;
  // font-family: 'Poppins', sans-serif;
  background-color: $main-color;
}

::selection {
  background-color: $orange;
  color: $white;
}

.container {
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }
}

.bold-text {
  font-weight: bold;
}

//SCROLLBAR
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #B3AFB3;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #B3AFB3;
}

::-webkit-scrollbar-track {
  background: #F0F0F0;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}

/***************************
*******HEADER NAVBAR********
****************************/

/* ANIMATION BURGER MENU */

.menu-btn .line {

  width: 20px;
  height: 5px;
  background-color: $white;
  display: block;
  margin: 3px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.menu-btn {
  cursor: pointer;
  float: right;
  margin-right: 20px;
  display: none;
}

.menu-btn.open .line:nth-child(2) {
  opacity: 0;
  background-color: $black;
}

.menu-btn.open .line:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
  background-color: $black;
}

.menu-btn.open .line:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
  background-color: $black;
}

@media only screen and (max-width: 1295px) {
  .menu-fullscreen {
    display: none;
  }

  .menu-btn {
    display: initial;
  }
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  visibility: hidden;
  opacity: 0;

  .mobile-menu-li {
    align-items: center;
    justify-content: center;
    font-size: 24px;
    white-space: nowrap;
    list-style-type: none;

    a {
      color: black;
      text-align: center;
      align-items: center;
      margin-bottom: 0rem;
    }
  }

}

.mobile-menu.open {
  background: #F7DC6F;
  opacity: 1;
  visibility: visible;
  transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -ms-transition: 0.2s linear;

  a {
    margin-bottom: 1rem;
  }

  .menu-responsive .mobile-menu-socialnetwork {
    a {
      bottom: 0%;
      transition: 0.2s linear;
    }
  }
}

.menu-responsive .mobile-menu-socialnetwork {
  position: absolute;
  bottom: 6%;
  text-align: center;
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: background 0.2s linear;

  a {
    padding: 0;
    color: $black;
    justify-content: center;
    margin: 1rem;
  }
}

/* NAVIGATION CLASSIC MENU */

header {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;

  nav {
    position: fixed;
    z-index: 1;
    background: $dark-blue;
    width: 100%;
    float: right;
    // padding-top: 80px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    a {
      text-decoration: none;
      color: $white;
      float: left;
      padding-right: 30px;
      padding-left: 30px;
    }

    a:hover {
      color: $yellow;
    }

    .navbar {
      .logo-topbar {
        margin-left: 220px;
      }

      @media (max-width: 1295px) {
        .logo-topbar {
          margin-left: 20px;
        }
      }

      .menu-fullscreen {

        ul {
          display: flex;
          margin: 0;
          padding: 0;
          list-style-type: none;
          overflow: hidden;

          li {
            float: left;
          }
        }

        .nav-right {
          margin-left: auto;
          padding-right: 40px;
        }

      }

    }


  }

}

.button-primary {
  padding: 5px;
  margin-right: 15px;
  border: 7px solid black;
  overflow: hidden;
  background: $white;
  text-shadow: 2px 2px $orange;
  //box-shadow: 8px 8px 0px -1px $orange;
  box-shadow: 5px 5px 0px -1px $orange, inset 4px 4px 0px 0px $orange;
  text-decoration: none;
  text-align: center;

  &:hover {
    transition-duration: 200ms;
    text-shadow: 1px 1px $orange;
    box-shadow: 3px 3px 0px -1px $orange;
  }

}

.link-text {
  color: #fff;
  overflow: hidden;
  // text-shadow: 2px 2px $yellow;
  text-decoration: none;
  border-bottom: 3px solid $white;
  //border-bottom:2px solid black;
  // box-shadow: 0 2px 0px -0px $yellow;
  padding-bottom: 1px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    transition-duration: 200ms;
    text-shadow: 1px 1px $yellow;
    box-shadow: 0 1px 0px -0px $yellow;
  }

}

/***************************
************HOME************
****************************/

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $resume-bg;
}

.homepage {
  display: flex;
  align-items: center;
  color: $white;
  padding-top: 30vh;
  padding-bottom: 15vh;

  @media (max-width: 768px) {
    display: block;
    padding-top: 15vh;
    //text-align: center;
  }

  h1 {
    text-shadow: 2px 2px $orange;
    font-size: 44px;
  }

  p {
    font-size: 24px;
  }

  a {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 20px;
    }
  }


}

.logo {
  img {
    width: 18rem;
    position: relative;
    justify-content: center;
    border-radius: 50%;
    border: 5px solid $white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (min-width: 768px) {
      margin-right: 50px;
    }

    @media (max-width: 768px) {
      text-align: center;
      margin-top: 10%;
      margin-bottom: 0.5rem;
      align-items: center;
      width: 16rem;
    }
  }

  text-align: center;
}


/***************************
**********CONTACT***********
****************************/

.contact-container {
  display: flex;
  justify-content: center;

  .contact {
    padding-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    min-height: 100vh;
    max-width: 35rem;
    text-align: center;

    .photo-head {

      img {
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        position: relative;
        justify-content: center;
        text-align: center;
        border-radius: 50%;
      }
    }

    ul {
      list-style-type: none;
      overflow: hidden;
      margin: 0;
      padding: 0;

      li {
        vertical-align: middle;
        margin-top: 1rem;
        margin-bottom: 1rem;

        a {
          //border: 1px solid #000000;
          display: block;
          text-decoration: none;
          padding: 14px 16px;
          //color: $white;
        }
      }
    }

    li {
      img {
        width: 15px;
      }
    }

    .container {
      margin-top: 20px;
      padding: 20px;
      border: 1px solid #322a25;
      border-radius: 4px;

      input {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        border-radius: 4px;
      }

      textarea {
        width: 100%;
        padding: 12px 20px;
        border-radius: 4px;
        height: 150px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      button {
        background-color: #322a25;
        color: $white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }


  }
}

.linkedin {
  background-color: #0a66c2;
  color: $white;

  &:hover {
    background-color: #003771;
  }
}

.insta {
  color: $white;
  background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);

  &:hover {
    transition: 1ms all;
    background: linear-gradient(270deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  }
}

.phone {
  color: $white;
  background-color: #61c183;

  &:hover {
    background-color: #2d8e4f;
  }
}

.mail {
  background-color: #6685d7;
  color: $white;

  &:hover {
    background-color: #274590;
  }
}

.mywebsite {
  border: 3px solid #000000;
  background-color: $black;
  color: $white;

  &:hover {
    color: $black;
    background-color: $white;
  }
}

.cv {
  border: 3px solid $dark-blue;
  background-color: $dark-blue;
  color: $white;

  &:hover {
    color: $dark-blue;;
    background-color: $white;
  }
}

footer {
  display: block;
  text-align: center;
  background: #25252B;
  color: $white;
  padding: 20px;
  margin-top: 20px;

  .social-network {
    margin-top: 10px;
    font-size: 30px;
  }

  a {
    margin-right: 10px;
    margin-left: 10px;
    color: $white;

    &:hover {
      color: $yellow;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
}

/***************************
*********Experiences********
****************************/

.experience {
  background-color: $section-color;
  padding-bottom: 80px;
  text-align: right;
  display: grid;

  h1 {
    margin-bottom: 5%;
    display: inline-block;
    margin-top: -30px;
    border: 7px solid black;
    overflow: hidden;
    background-color: $white;
    text-shadow: 2px 2px $orange;
    box-shadow: 5px 5px 0px -1px $orange, inset 4px 4px 0px 0px $orange;
    padding: 2px 10px;
    transition-duration: 200ms;

    &:hover {
      transition-duration: 200ms;
      text-shadow: 1px 1px $orange;
      box-shadow: 1px 1px 0px -1px $orange;
    }
  }


  .card-container {
    justify-content: center;
    text-align: center;
    // display: grid;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    margin: 10px;
    flex-wrap: wrap;
  }

  .card {
    display: grid;
    background: $orange;
    padding: 30px;
    color: $white;
    border-radius: 5px;
  }


  .experience-card {
    width: 220px;
    color: $white;
    background: #515368;
    padding: 10px;
    margin: 40px 15px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.3);

    .experience-img {
      padding: 5px;
      margin-top: -70px;
      background-color: #515368;
      border-radius: 50%;
      display: inline-block;
      justify-content: center;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        align-items: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        background-color: #ffff;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
        padding: 30px
      }
    }

    .experience-card-date {
      margin-top: 80px;
      text-align: right;
    }

    .experience-card-content {
      text-align: left;

      span {
        color: #5790c9;
      }
    }
  }

}


/***************************
**********Skills************
****************************/


.skills {
  padding-bottom: 80px;

  .header-skills {
    display: inline-block;
    margin-top: -55px;
    margin-bottom: 5%;

    h1 {
      border: 7px solid black;
      overflow: hidden;
      background-color: $white;
      text-shadow: 2px 2px $orange;
      box-shadow: 5px 5px 0px -1px $orange, inset 4px 4px 0px 0px $orange;
      padding: 2px 10px;
      transition-duration: 200ms;

      &:hover {
        transition-duration: 200ms;
        text-shadow: 1px 1px $orange;
        box-shadow: 1px 1px 0px -1px $orange;
      }
    }
  }

  h2 {
    margin-top: 50px;
    padding-left: 35px;
  }
}

.skills-container {
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 4fr));
  grid-gap: 20px;

  .type-skill {
    border: 5px solid #000000;
    border-radius: 1px;
    width: 200px;
    padding-right: 20px;
    padding-left: 20px;
    transform: skewX(-20deg);

    h2 {
      transform: skewX(20deg);
    }

    div {
      vertical-align: middle;
      transform: skewX(20deg);
    }

    p {
      transform: skewX(20deg);
    }
  }
}

/***************************
**********Skill Card********
****************************/

.skill-card {
  align-items: center;
  display: flex;

  .skill-img {
    border-radius: 50%;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    padding: 20px;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      align-items: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .skill-text {
    margin-left: 30px;
  }

  .exp-year {
    font-weight: bold;
  }
}

/***************************
**********Formation********
****************************/

.formation {
  h1 {
    margin-bottom: 5%;
    display: inline-block;
    margin-top: -30px;
    border: 7px solid black;
    overflow: hidden;
    background-color: $white;
    text-shadow: 2px 2px $orange;
    box-shadow: 5px 5px 0px -1px $orange, inset 4px 4px 0px 0px $orange;
    padding: 2px 10px;
    transition-duration: 200ms;

    &:hover {
      transition-duration: 200ms;
      text-shadow: 1px 1px $orange;
      box-shadow: 1px 1px 0px -1px $orange;
    }
  }

  .card-container {
    justify-content: center;
    text-align: center;
    display: grid;
  }

  .cube {
    margin: 20px 10px;
    background: #ced1f0;
    padding: 5rem 2rem;
    box-shadow: 5px 5px 0px -1px $orange;
    max-width: 40rem;
    text-align: center;

    span {
      font-size: 14px;
      color: #7c7c7c;
    }

    h2 {
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }
}